<template>
  <layout>
    <login-form class="login-form" :mainTitle="loginWay === 1 ? '手机验证登录' : '账号密码登录'">
      <div style="width:100%">
        <template v-if="loginWay === 1">
          <input-item
            ref="phone"
            inputPlaceholder="请输入手机号"
            :input-append-operation="`${canSentSms ? '发送验证码' : second }`"
            @operationHandler="sendValidCode"
          />
          <input-item
            ref="validCode"
            inputPlaceholder="请输入验证码"
            :inputPrependIcon="validCodeIcon"
            :inputShowAppend="false"
          />
        </template>
        <template v-else>
          <input-item
            ref="phone"
            :autoComplete="'off'"
            inputPlaceholder="请输入手机号"
            :inputShowAppend="false"
          />
          <input-item
            ref="password"
            inputPlaceholder="请输入密码"
            :autoComplete="'off'"
            :inputPrependIcon="passwordIcon"
            :inputShowAppend="false"
            :inputShowPassword="true"
          />
        </template>
        <div class="other-operation">
          <span @click="toRegister">立即注册</span>
          <span @click="forgetPwd">忘记密码</span>
        </div>
      </div>
      <el-button class="login-btn" type="primary" @click="handlerLogin">登录</el-button>
      <p class="change-account-btn" @click="switchLoginWay">
        切换{{ loginWay === 1 ? '账号密码' : '手机验证' }}登录
      </p>
      <img class="bottom-img" src="@/assets/images/icon_account@3x.png" />
    </login-form>

    <cap :is-show="validCodeShow" @showReceipt="validResult" />
    <user-info-dialog
      ref="userInfoDialog"
      :baseDialogVisible="userInfoIialogVisible"
      baseDialogWidth="50%"
      @closeDialog="userInfoIialogVisible = false"
    >
      <span slot="dialog-title">个人信息</span>
      <div class="form">
        <user-info-form ref="userForm" @submitSuccess="submitUserInfoSuccess" />
      </div>
      <span slot="dialog-footer">
        <el-button type="primary" style="width: 240px" @click="submitUserInfo">提交</el-button>
      </span>
    </user-info-dialog>
  </layout>
</template>

<script>
import RegExp from '@/utils/regExp';
import * as ApiLogin from '@/api/login';
import UserInfoDialog from '@/components/BaseDialog/index.vue';
import UserInfoForm from './components/UserInfoForm.vue';
import Cap from './components/Cap.vue';
import Layout from './components/LoginLayout.vue';
import LoginForm from './components/InputForm.vue';
import InputItem from './components/InputItem.vue';

const globalValidCodeIcon = require('@/assets/images/icon_valid_code@3x.png');
const globalPasswordIcon = require('@/assets/images/icon_password@3x.png');

export default {
  name: 'Login',
  components: {
    Layout,
    LoginForm,
    InputItem,
    Cap,
    UserInfoDialog,
    UserInfoForm,
  },
  data() {
    return {
      canSentSms: true,
      second: 60,
      userInfoIialogVisible: false,
      validCodeShow: false,
      loginWay: -1,
      validCodeIcon: globalValidCodeIcon,
      passwordIcon: globalPasswordIcon,
      loginForm: {
        phone: '',
      },
    };
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyUp);
  },
  destoryed() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    handleKeyUp(key) {
      if (key.keyCode === 13) {
        this.handlerLogin();
      }
    },
    // 忘记密码
    forgetPwd() {
      this.$router.push('/resetPassword');
    },
    // 切换登录方式
    switchLoginWay() {
      this.loginWay = -this.loginWay;
      this.$refs.phone.value = '';
      if (this.loginWay === 1) {
        this.$refs.password.value = '';
      } else {
        this.$refs.validCode.value = '';
      }
    },
    // 去注册
    toRegister() {
      this.$router.push('/register');
    },
    submitUserInfoSuccess() {
      this.userInfoIialogVisible = false;
      this.$router.push('/home');
    },
    submitUserInfo() {
      const { userId } = JSON.parse(localStorage.getItem('user'));
      this.$refs.userForm.submitForm(userId);
    },
    // 登录
    handlerLogin() {
      const params = {
        phone: this.$refs.phone.getInputValue(),
        source: 1,
      };
      if (!params.phone) {
        this.$message.messageError('手机号不准为空');
        return;
      }
      if (this.loginWay === 1) {
        params.code = this.$refs.validCode.getInputValue();
        params.scene = 1;
        if (!params.code) {
          this.$message.messageError('验证码不准为空');
          return;
        }
        ApiLogin.LoginByCode(params).then((res) => {
          if (res.code === 0) {
            const loginData = {
              token: 'hasLogin',
              userId: res.data.userId,
              userName: res.data.userName,
            };
            sessionStorage.setItem('sessionId', res.data.sessionId);
            this.$store.dispatch('login', loginData);
            localStorage.setItem('user', JSON.stringify(res.data));
            if (!res.data.userName) { // 用户还没提交用户注册信
              this.$router.push('/userInfo');
            } else {
              this.$router.push('/home');
            }
          } else {
            this.$message.messageError(res.message);
          }
        }).catch((e) => {});
      } else {
        params.password = this.$refs.password.getInputValue();
        if (!params.password) {
          this.$message.messageError('密码不准为空');
          return;
        }
        ApiLogin.LoginByPwd(params).then((res) => {
          if (res.code === 0) {
            sessionStorage.setItem('sessionId', res.data.sessionId);
            const loginData = {
              token: 'hasLogin',
              userId: res.data.userId,
            };
            this.$store.dispatch('login', loginData);
            localStorage.setItem('user', JSON.stringify(res.data));
            if (!res.data.userName) { // 用户还没提交用户注册信息
              this.$router.push('/userInfo');
            } else {
              this.$router.push('/home');
            }
          } else {
            this.$message.messageError(res.message);
          }
        }).catch((e) => {});
      }
    },
    // 打开图片验证
    sendValidCode() {
      if (!this.canSentSms) return;
      // 验证手机号是否合法
      const phone = this.$refs.phone.getInputValue();
      if (!RegExp.phone.test(phone)) {
        this.$message.messageError('请输入正确的手机号');
        return;
      }
      this.validCodeShow = true;
    },
    validResult(val) {
      if (val) {
        this.validCodeShow = false;
        const params = {
          phone: this.$refs.phone.getInputValue(),
          scene: 1,
          source: 1,
        };
        ApiLogin.sendSms(params).then((res) => {
          if (res.code === 0) {
            this.$message.messageSuccess('验证码已发送');
            this.timeDown();
          } else {
            this.$message.messageError(res.message);
          }
        });
      }
    },
    timeDown() {
      this.canSentSms = false;
      const result = setInterval(() => {
        this.second -= 1;
        if (this.second <= 0) {
          clearInterval(result);
          this.second = 60;
          this.canSentSms = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  .input-item {
    width: 80%;
    margin: 20px auto;

    /deep/ .input-prepend-icon {
      width: 24px;
      height: 24px;
    }
    /deep/ .el-input-group__prepend {
      padding: 0 10px;
      background-color: transparent;
      border: unset;
    }

    &:nth-child(2) {
      margin-bottom: 0;
    }
  }

  .other-operation {
    width: 80%;
    margin: 10px auto;
    text-align: right;
    font-size: 12px;

    span {
      color: #409EFF;
    }

    span:hover {
      cursor: pointer;
    }

    span:first-child {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #eeeeee;
    }
  }

  .login-btn {
    width: 80%;
  }

  .change-account-btn {
    width: 80%;
    padding-bottom: 15px;
    font-size: 12px;
    color: #409EFF;
    border-bottom: 1px solid #eeeeee;

    &:hover {
      cursor: pointer;
    }
  }

  .bottom-img {
    width: 24px;
    height: 24px;
  }
  @media screen and (min-width: 1920px) {
    .login-btn {
      width: 316px;
      height: 48px;
    }

    .bottom-img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
